/*!
 * Responsive Tables v5.0.4 (http://gergeo.se/RWD-Table-Patterns)
 * This is an awesome solution for responsive tables with complex data.
 * Authors: Nadan Gergeo <nadan.gergeo@gmail.com> (www.gergeo.se) & Maggie Wachs (www.filamentgroup.com)
 * Licensed under MIT (https://github.com/nadangergeo/RWD-Table-Patterns/blob/master/LICENSE-MIT)
 */
.dropdown-menu>li.checkbox-row {
 padding:5px 20px
}
.dropdown-menu li.checkbox-row {
 display:block;
 clear:both;
 font-weight:normal;
 line-height:20px;
 color:#333;
 white-space:nowrap
}
.dropdown-menu li.checkbox-row label {
 font-weight:normal
}
.dropdown-menu li.checkbox-row:hover,
.dropdown-menu li.checkbox-row input:hover,
.dropdown-menu li.checkbox-row label:hover {
 cursor:pointer
}
.no-touch .dropdown-menu>.checkbox-row:hover,
.no-touch .dropdown-menu>.checkbox-row:active {
 text-decoration:none;
 color:#262626;
 background-color:#f5f5f5
}
.btn-toolbar {
 margin-bottom:20px
}
.lt-ie8 .btn-toolbar {
 display:none
}
.table-responsive {
 border-radius:3px;
 border:1px solid #ddd;
 margin-bottom:20px
}
.table-responsive>.table {
 margin-bottom:0
}
.table-responsive>.table-bordered {
 border:0
}
.table-responsive>.table-bordered>thead>tr>th:first-child,
.table-responsive>.table-bordered>tbody>tr>th:first-child,
.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.table-responsive>.table-bordered>thead>tr>td:first-child,
.table-responsive>.table-bordered>tbody>tr>td:first-child,
.table-responsive>.table-bordered>tfoot>tr>td:first-child {
 border-left:0
}
.table-responsive>.table-bordered>thead>tr>th:last-child,
.table-responsive>.table-bordered>tbody>tr>th:last-child,
.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.table-responsive>.table-bordered>thead>tr>td:last-child,
.table-responsive>.table-bordered>tbody>tr>td:last-child,
.table-responsive>.table-bordered>tfoot>tr>td:last-child {
 border-right:0
}
.table-responsive>.table-bordered>tbody>tr:last-child>th,
.table-responsive>.table-bordered>tfoot>tr:last-child>th,
.table-responsive>.table-bordered>tbody>tr:last-child>td,
.table-responsive>.table-bordered>tfoot>tr:last-child>td {
 border-bottom:0
}
.table-responsive[data-pattern="priority-columns"] {
 position:relative;
 width:100%;
 margin-bottom:20px;
 overflow-y:hidden;
 overflow-x:auto;
 -ms-overflow-style:-ms-autohiding-scrollbar;
 border-radius:3px;
 border:1px solid #ddd;
 -webkit-overflow-scrolling:touch
}
.table-responsive[data-pattern="priority-columns"]>.table {
 margin-bottom:0
}
.table-responsive[data-pattern="priority-columns"]>.table>thead>tr>th,
.table-responsive[data-pattern="priority-columns"]>.table>tbody>tr>th,
.table-responsive[data-pattern="priority-columns"]>.table>tfoot>tr>th,
.table-responsive[data-pattern="priority-columns"]>.table>thead>tr>td,
.table-responsive[data-pattern="priority-columns"]>.table>tbody>tr>td,
.table-responsive[data-pattern="priority-columns"]>.table>tfoot>tr>td {
 white-space:nowrap
}
.table-responsive[data-pattern="priority-columns"]>.table-bordered {
 border:0
}
.table-responsive[data-pattern="priority-columns"]>.table-bordered>thead>tr>th:first-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tbody>tr>th:first-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tfoot>tr>th:first-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>thead>tr>td:first-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tbody>tr>td:first-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tfoot>tr>td:first-child {
 border-left:0
}
.table-responsive[data-pattern="priority-columns"]>.table-bordered>thead>tr>th:last-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tbody>tr>th:last-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tfoot>tr>th:last-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>thead>tr>td:last-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tbody>tr>td:last-child,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tfoot>tr>td:last-child {
 border-right:0
}
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tbody>tr:last-child>th,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tfoot>tr:last-child>th,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tbody>tr:last-child>td,
.table-responsive[data-pattern="priority-columns"]>.table-bordered>tfoot>tr:last-child>td {
 border-bottom:0
}
.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority],
.mq.js.lt-ie10 .sticky-table-header th[data-priority],
.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority],
.mq.js.lt-ie10 .sticky-table-header td[data-priority] {
 display:none
}
.lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="1"],
.lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="1"],
.lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="1"],
.lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="1"] {
 display:inline
}
.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="1"],
.mq.js.lt-ie10 .sticky-table-header th[data-priority="1"],
.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="1"],
.mq.js.lt-ie10 .sticky-table-header td[data-priority="1"] {
 display:table-cell
}
@media screen and (min-width:480px) {
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="2"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="2"],
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="2"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="2"] {
  display:inline
 }
 .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="2"],
 .mq.js.lt-ie10 .sticky-table-header th[data-priority="2"],
 .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="2"],
 .mq.js.lt-ie10 .sticky-table-header td[data-priority="2"] {
  display:table-cell
 }
}
@media screen and (min-width:640px) {
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="3"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="3"],
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="3"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="3"] {
  display:inline
 }
 .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="3"],
 .mq.js.lt-ie10 .sticky-table-header th[data-priority="3"],
 .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="3"],
 .mq.js.lt-ie10 .sticky-table-header td[data-priority="3"] {
  display:table-cell
 }
}
@media screen and (min-width:800px) {
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="4"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="4"],
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="4"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="4"] {
  display:inline
 }
 .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="4"],
 .mq.js.lt-ie10 .sticky-table-header th[data-priority="4"],
 .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="4"],
 .mq.js.lt-ie10 .sticky-table-header td[data-priority="4"] {
  display:table-cell
 }
}
@media screen and (min-width:960px) {
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="5"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="5"],
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="5"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="5"] {
  display:inline
 }
 .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="5"],
 .mq.js.lt-ie10 .sticky-table-header th[data-priority="5"],
 .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="5"],
 .mq.js.lt-ie10 .sticky-table-header td[data-priority="5"] {
  display:table-cell
 }
}
@media screen and (min-width:1120px) {
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="6"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header th[data-priority="6"],
 .lt-ie9.mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="6"],
 .lt-ie9.mq.js.lt-ie10 .sticky-table-header td[data-priority="6"] {
  display:inline
 }
 .mq.js .table-responsive[data-pattern="priority-columns"] th[data-priority="6"],
 .mq.js.lt-ie10 .sticky-table-header th[data-priority="6"],
 .mq.js .table-responsive[data-pattern="priority-columns"] td[data-priority="6"],
 .mq.js.lt-ie10 .sticky-table-header td[data-priority="6"] {
  display:table-cell
 }
}
table.table-small-font {
 font-size:12px;
 -webkit-text-size-adjust:none;
 line-height:1.5em
}
table.focus-on tbody tr:hover {
 cursor:pointer
}
table.focus-on tbody tr.unfocused th,
table.focus-on tbody tr.unfocused td {
 color:#999;
 color:rgba(0,0,0,0.4)
}
table.focus-on tbody tr.focused th,
table.focus-on tbody tr.focused td {
 background-color:#5bc0de;
 color:#000
}
table th.col-hide,
table td.col-hide {
 display:none
}
table th.col-show,
table td.col-show {
 display:inline
}
.lt-ie9 table th.col-show,
.lt-ie9 table td.col-show {
 display:table-cell
}
.lt-ie9 table.display-all th,
.lt-ie9 table.display-all td {
 display:inline !important
}
table.display-all th,
table.display-all td {
 display:table-cell !important
}
.sticky-table-header {
 background-color:#fff;
 border:none;
 border-radius:0;
 border-top:1px solid #ddd;
 border-bottom:1px solid #ddd;
 position:absolute;
 top:0;
 visibility:hidden;
 z-index:990;
 overflow:hidden;
 min-width:100%
}
.sticky-table-header.fixed-solution {
 min-width:0;
 position:fixed
}
.lt-ie10 .sticky-table-header.fixed-solution {
 border-left:1px solid #ddd;
 border-right:1px solid #ddd;
 box-sizing:content-box;
 border-bottom:none
}
.sticky-table-header.border-radius-fix {
 border-bottom-left-radius:3px;
 border-bottom-right-radius:3px
}
.sticky-table-header>.table {
 margin-bottom:0
}
.sticky-table-header>.table>thead>tr>th,
.sticky-table-header>.table>tbody>tr>th,
.sticky-table-header>.table>tfoot>tr>th,
.sticky-table-header>.table>thead>tr>td,
.sticky-table-header>.table>tbody>tr>td,
.sticky-table-header>.table>tfoot>tr>td {
 white-space:nowrap
}
.sticky-table-header>.table-bordered {
 border:0
}
.sticky-table-header>.table-bordered>thead>tr>th:first-child,
.sticky-table-header>.table-bordered>tbody>tr>th:first-child,
.sticky-table-header>.table-bordered>tfoot>tr>th:first-child,
.sticky-table-header>.table-bordered>thead>tr>td:first-child,
.sticky-table-header>.table-bordered>tbody>tr>td:first-child,
.sticky-table-header>.table-bordered>tfoot>tr>td:first-child {
 border-left:0
}
.sticky-table-header>.table-bordered>thead>tr>th:last-child,
.sticky-table-header>.table-bordered>tbody>tr>th:last-child,
.sticky-table-header>.table-bordered>tfoot>tr>th:last-child,
.sticky-table-header>.table-bordered>thead>tr>td:last-child,
.sticky-table-header>.table-bordered>tbody>tr>td:last-child,
.sticky-table-header>.table-bordered>tfoot>tr>td:last-child {
 border-right:0
}
.sticky-table-header>.table-bordered>tbody>tr:last-child>th,
.sticky-table-header>.table-bordered>tfoot>tr:last-child>th,
.sticky-table-header>.table-bordered>tbody>tr:last-child>td,
.sticky-table-header>.table-bordered>tfoot>tr:last-child>td {
 border-bottom:0
}
