.font {
  font-family: "Roboto", sans-serif;
  color: #5b626b;
  font-size: 11.5px;
}

h3 {
  margin-bottom: 0;
}

h4 {
  color: #949a9e;
  font-size: 18px;
  margin-bottom: -3px;
  margin-top: unset;
  font-family: helvetica;
}

.logo {
  text-align: center;
  margin: 0 auto;
}

.logo img {
  height: 70px;
}

.login {
  box-sizing: border-box;
  padding: 5%;
}

.bloco {
  /* width: 430px; */
  background-color: white;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 20px 11px 15px rgba(0, 0, 0, 0.08);
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 10px;
  margin: auto;
  flex: 1 1 auto;
  padding: 1.1rem;
  margin-top: 5%;
  /* font-size: 13px; */
}

input::placeholder {
  font-size: 12px;
}

p {
  text-align: center;
  color: #949a9e;
}

.user {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-right: 2%;
  box-sizing: border-box;
  outline: none;
}

.checkbox {
  position: relative;
  padding-left: 1.5rem;
  width: 20px;
  border-style: none;
}

.element-hover svg {
  display: none;
}
.element-hover:hover svg {
  display: inline-block;
}