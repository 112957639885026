.rdrCalendarWrapper:not(:hover) .rdrMonthAndYearWrapper, 
.rdrCalendarWrapper:not(:hover) .rdrDays, 
.rdrCalendarWrapper:not(:hover) .rdrWeekDays {
  display: none;
}

.rdrCalendarWrapper:hover .rdrMonths {
  display: block;
  position: absolute;
  z-index: 9;
  background-color: white;
  margin-top: 70px;
}

.rdrCalendarWrapper:hover .rdrMonthAndYearWrapper {
  position: absolute;
  width: 300px;
  z-index: 11;
  margin-top: 30px;
}

.rdrDateDisplayWrapper {
  background-color: initial;
}

.rdrDateDisplay { 
  margin: 0px;
}

.rdrCalendarWrapper {
  width: 300px;
  height: 40px;
}