.page404 {
  color: #000;
}

.page404 img {
  margin: 0 auto;
  background-size: contain;
}

.page404 svg {
  width: 100%;
  visibility: hidden;
}
.page404 h1 {
  font-size: 7.5em;
  margin: 15px 0px;
  font-weight:bold;
}

.page404 a {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
  border: 4px solid #424242;
  color: #424242;
}

.page404 a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: #424242;
  z-index: -1;
  transition: 0.2s ease;
  border-radius: 30px;
}

.page404 a:hover {
  color: #fff;
  background: #424242;
  transition: 0.2s ease;  
}

.page404 a:hover:before {
  width: 100%;
}
