.invalid-slug {
  color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.invalid-slug h1 {
  font-size: 7.5em;
  font-weight:bold;
}
